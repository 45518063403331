import axios from "axios";

// const TOKEN = JSON.parse(localStorage.getItem("token"));
// const BASE_URL = process.env.APP_SERVER_URL;
// // const BASE_URL = process.env.REACT_APP_SERVER_URL;

// const api = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     Accept: `application/json`,
//     "Content-Type": `application/json`,
//     Authorization: TOKEN !== null ? `Bearer ${TOKEN}` : null,
//     "X-Requested-With": "XMLHttpRequest",
//   },
//   validateStatus: () => true,
//   // auth: false,
// });
// api.interceptors.request.use((config) => {
//   const token = localStorage.getItem("token");
//   config.headers.Authorization = token ? JSON.parse(token) : "";
//   console.log(config);
//   return config;
// });
// api.interceptors.response.use(
//   (res) => {
//     console.log("res", res);
//     return res;
//   },
//   (err) => {
//     console.log("error: ", err);
//     return Promise.reject(err);
//   }
// );

const TOKEN = JSON.parse(localStorage.getItem("token"));
const BASE_URL = process.env.APP_SERVER_URL;
// const BASE_URL = process.env.REACT_APP_SERVER_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common["Authorization"] =
  TOKEN !== null ? `Bearer ${TOKEN}` : null;

axios.defaults.headers.common["Accept"] = `application/json`;
axios.defaults.headers.common["Content-Type"] = `application/json`;

// export default api;
export default axios;
