import React, { Component } from "react";
import {
  FaRegEdit,
  FaUpload,
  FaRegWindowRestore,
  FaRegFrown,
} from "react-icons/fa";
import { Button, Container, Modal } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import ReactLoading from "react-loading";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import toTitleconverttoTitle from "../../util/toTitleConveter";
import tzPhoneNumberValidator from "../../util/tzPhoneNumberValidator";

class PerformCancellation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      confimation_code: "",
      fields: {},
      errors: {},
      phone: "",
      answer: "",
    };
  }
  openModelForm = (e) => {
    e.preventDefault();
    // if (this.validateQuestion()) {
    //   if (this.state.relative !== "") {
    this.setState({ yesIsOpen: true });
    this.setState({ noIsOpen: false });
    //   }
    // }
  };

  closeModal = () => {
    this.setState({
      yesIsOpen: false,
      noIsOpen: false,
      loading: "",
      fields: {},
      errors: {},
      network_error: false,
      particular_exists: false,
      selectedFile: "",
      setIsSelected: false,
      isSelected: false,
    });
  };

  validateAnswer = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["answer"] || typeof fields["answer"] === undefined) {
      formIsValid = false;
      errors["answer"] = "* Please select either Yes or No.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    console.log(this.state.fields);
  };

  submitCancellation = async (e) => {
    e.preventDefault();
    if (this.validateAnswer()) {
      if (this.state.answer === "1") {
        let payLoad = {
          admno: parseInt(localStorage.getItem("id")),
        };
        await api
          .post(params.PERFORM_TCU_CANCELLATION, payLoad)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                alert(res.data.stsMessage);
                window.location.reload(false);
              } else {
                alert(res.data.stsMessage);
                window.location.reload(false);
              }
            }
          })
          .catch((err) => {});
      } else {
        this.setState({
          noIsOpen: false,
          answer: "",
          loading: "",
          errors: {},
          yesIsOpen: false,
        });

        // this.props.history.push("/after/confirm");
      }
    }
  };
  render() {
    return (
      <div>
        <div>
          <form onSubmit={this.openModelForm}>
            <div className="form-group">
              <label>
                {" "}
                If you wish to reject the admission, click the button below to
                reject your admission at TIA
                <font className="label-comment">*</font>
              </label>
              <div className="form-group">
                <Button
                  type="submit"
                  variant="primary"
                  className="btn btn-round  btn-sm"
                  // disabled
                >
                  <FaRegFrown />
                  {"     "} Reject Admission
                </Button>
              </div>
            </div>
          </form>
        </div>

        <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Answer the following qusetion accordingly
            </div>
          </Modal.Header>
          <form onSubmit={this.submitCancellation}>
            <Modal.Body>
              {this.state.loading === true ? (
                <>
                  <ReactLoading type="cylon" color="#000" />
                </>
              ) : null}
              {this.state.network_error === true ? (
                <>
                  <Alert onClose={this.closeNetworkWarning} severity="error">
                    You are facing netwok problems — Please try again later or
                    contact the system administrator!
                  </Alert>
                </>
              ) : null}

              <div className="form-group">
                <label>
                  Are you sure you want to reject your admission?
                  <font className="label-comment">*</font>
                </label>
                <select
                  name="answer"
                  className="selection-box  input-placeholder"
                  value={this.state.answer}
                  onChange={this.handleInputState}
                >
                  <option value=""> -- Select the answer-- </option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
                {this.state.errors.answer ? (
                  <div className="errorMsg">{this.state.errors.answer}</div>
                ) : null}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
              >
                <FaUpload />
                {"     "}Save
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}

export default PerformCancellation;
