import React, { Component } from "react";
import { FaRegEdit, FaUpload, FaRegSave } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import ReactLoading from "react-loading";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import toTitleconverttoTitle from "../../util/toTitleConveter";
import tzPhoneNumberValidator from "../../util/tzPhoneNumberValidator";
import changeStage from "../../util/changeStage";
import { withRouter } from "react-router-dom";

class AddKin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relative: "",
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      level: null,
      isSelected: false,
      loading: "",
      fields: {},
      errors: {},
      tzregion: "",
      districts: [],
      tzdistrict: "",
      region: "",
      district: "",
      full_name: "",
      address: "",
      phone: "",
      country: "",
      district: "",
      residence: "",
      region: "",
    };
  }

  closeModal = () => {
    this.setState({
      yesIsOpen: false,
      noIsOpen: false,
      loading: "",
      fields: {},
      errors: {},
      network_error: false,
      particular_exists: false,
      selectedFile: "",
      setIsSelected: false,
      isSelected: false,
    });
  };
  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    if (e.target.name === "residence") {
      if (e.target.value === "") {
        this.setState({ showRegion: "" });
      }
      if (e.target.value === "Tanzania") {
        this.setState({ showRegion: true });
      }
      if (e.target.value !== "Tanzania" && e.target.value !== "") {
        this.setState({ showRegion: false });
      }
    }
    if (e.target.name === "tzregion") {
      this.setState({ showDistrict: true });
      if (e.target.value === "") {
        this.setState({ district_loadig: false });
      }
      if (e.target.value !== "") {
        this.setState({ districts: [] });
        const id = e.target.value.split(",");
        this.getDistrict(parseInt(id[0]));
        this.setState({ region: id[1] });
      }
    }
  };
  getDistrict = async (id) => {
    this.setState({ district_loadig: true });
    try {
      await api
        .get(params.DISTRICT_LIST + "/" + id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                districts: res.data.data,
                district_loadig: false,
              });
            }
          } else {
            this.setState({});
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  openModelForm = (e) => {
    e.preventDefault();
    if (this.validateQuestion()) {
      if (this.state.relative !== "") {
        this.setState({ yesIsOpen: true });
        this.setState({ noIsOpen: false });
      }
    }
  };
  validateQuestion = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["relative"]) {
      formIsValid = false;
      errors["relative"] = "* Please select the next of kin.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  validateFormNextofKin = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    const valuet = fields["full_name"];
    if (!fields["full_name"] || !isNaN(+valuet)) {
      formIsValid = false;
      errors["full_name"] = "* Please fill in full name in text.";
    }

    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "* Please fill in address.";
    }

    if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["citizenship"] || typeof fields["citizenship"] === undefined) {
      formIsValid = false;
      errors["citizenship"] = "* Please select citizenship.";
    }
    if (!fields["residence"] || typeof fields["residence"] === undefined) {
      formIsValid = false;
      errors["residence"] = "* Please select country of residency.";
    }
    if (fields["residence"] === "Tanzania") {
      if (!fields["tzregion"] || typeof fields["tzregion"] === undefined) {
        formIsValid = false;
        errors["tzregion"] = "* Please select region.";
      }
      if (!fields["tzdistrict"] || typeof fields["tzdistrict"] === undefined) {
        formIsValid = false;
        errors["tzdistrict"] = "* Please select district.";
      }
    }
    if (fields["residence"] !== "Tanzania") {
      if (!fields["region"] || typeof fields["region"] === undefined) {
        formIsValid = false;
        errors["region"] = "* Please fill the  region.";
      }
      if (!fields["district"] || typeof fields["district"] === undefined) {
        formIsValid = false;
        errors["district"] = "* Please fill the  district.";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  submitnextofkinInformation = async (e) => {
    e.preventDefault();
    if (this.validateFormNextofKin()) {
      let payLoad = {
        fullname: this.state.full_name,
        relationship: this.state.relative,
        applicationProfileId: parseInt(localStorage.getItem("id")),
        cellPhone: this.state.phone,
        country: this.state.residence,
        region:
          this.state.region !== "" ? this.state.region : this.state.tzregion,
        district:
          this.state.district !== ""
            ? this.state.district
            : this.state.tzdistrict,
        postalBox: this.state.address,
      };
      // let payLoad_2 = {
      //   applicationProfileId: parseInt(localStorage.getItem("id")),
      // };
      // let result = await changeStage(payLoad_2);
      await api
        .post(params.ADD_NEXTOFKIN, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                yesIsOpen: false,
                noIsOpen: false,
                loading: false,
                redirect: true,
                full_name: "",
                network_error: false,
              });
              // this.props.history.push("/personal/nextofkin");
              window.location.reload();
            } else {
              this.setState({
                network_error: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ network_error: true, loading: false });
        });
    }
  };
  render() {
    return (
      <>
        <form onSubmit={this.openModelForm}>
          <div className="form-group">
            <label>
              {" "}
              Select Next of Kin (Close Relative)
              <font className="label-comment">*</font>
            </label>
            <select
              name="relative"
              className="selection-box  input-placeholder"
              value={this.state.relative}
              onChange={this.handleInputState}
            >
              <option key={0} value="">
                {" "}
                -- Select the relative --{" "}
              </option>
              <option key={1} value="Father">
                Father
              </option>
              <option key={2} value="Mother">
                Mother
              </option>
              <option key={3} value="Uncle">
                Uncle
              </option>
              <option key={4} value="Aunt">
                Aunt
              </option>
              <option key={5} value="Brother">
                Brother
              </option>
              <option key={6} value="Sister">
                Sister
              </option>
              <option key={7} value="Guardian">
                Guardian
              </option>
              <option key={8} value="Wife">
                Wife
              </option>
              <option key={9} value="Husband">
                Husband
              </option>
              <option key={10} value="Son">
                Son
              </option>
              <option key={11} value="Daughter">
                Daughter
              </option>
              <option key={12} value="Nephew">
                Nephew
              </option>
              <option key={13} value="Niece">
                Niece
              </option>
            </select>
            {this.state.errors.relative ? (
              <div className="errorMsg">{this.state.errors.relative}</div>
            ) : null}
          </div>
          <div className="form-group">
            <Button
              type="submit"
              variant="primary"
              className="btn btn-round  btn-sm"
            >
              <FaRegSave />
              {"     "} Submit Relative
            </Button>
          </div>
        </form>

        <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <div className="float-center card-header-title">
              <FaRegEdit />
              Fill in your {this.state.relative}'s Details
            </div>
          </Modal.Header>
          <form onSubmit={this.submitnextofkinInformation}>
            <Modal.Body>
              {this.state.loading === true ? (
                <>
                  <ReactLoading type="cylon" color="#000" />
                </>
              ) : null}
              {this.state.network_error === true ? (
                <>
                  <Alert onClose={this.closeNetworkWarning} severity="error">
                    You are facing netwok problems — Please try again later or
                    contact the system administrator!
                  </Alert>
                </>
              ) : null}

              <div className="form-group">
                <label>
                  Next Of Kin Full Name
                  <font className="label-comment">*</font>
                </label>
                <input
                  type="text"
                  name="full_name"
                  className="form-control input-placeholder"
                  value={this.state.full_name}
                  onChange={this.handleInputState}
                  placeholder="Full Name"
                ></input>
                {this.state.errors.full_name ? (
                  <div className="errorMsg">{this.state.errors.full_name}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label>
                  Phone Number
                  <font className="label-comment">*</font>
                </label>
                <input
                  type="text"
                  name="phone"
                  className="form-control input-placeholder"
                  value={this.state.phone}
                  onChange={this.handleInputState}
                  placeholder="Phone"
                ></input>
                {this.state.errors.phone ? (
                  <div className="errorMsg">{this.state.errors.phone}</div>
                ) : null}
              </div>

              <div className="form-group">
                <label>
                  Contact Address
                  <font className="label-comment">*</font>
                </label>
                <input
                  type="text"
                  name="address"
                  className="form-control input-placeholder"
                  value={this.state.address}
                  onChange={this.handleInputState}
                  placeholder="Contact Address"
                ></input>
                {this.state.errors.address ? (
                  <div className="errorMsg">{this.state.errors.address}</div>
                ) : null}
              </div>
              <div className="form-group">
                <label>
                  Residence
                  <font className="label-comment">*</font>
                </label>
                {/* <fieldset className="scheduler-border"> */}
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-6">
                      <label className="label-class">
                        Citizeship
                        <font className="label-comment">*</font>
                      </label>
                      <select
                        type="text"
                        name="citizenship"
                        value={this.state.citizenship}
                        onChange={this.handleInputState}
                        className="custom-select custom-select-md"
                        aria-readonly="true"
                      >
                        <option key="" value="">
                          -- Select citizenship --{" "}
                        </option>
                        {this.props.countries.map((options) => (
                          <option key={options.id} value={options.citizenship}>
                            {options.citizenship}
                          </option>
                        ))}
                      </select>
                      {this.state.errors.citizenship ? (
                        <div className="errorMsg">
                          {this.state.errors.citizenship}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-sm-6">
                      <label className="label-class">
                        Country of Residence
                        <font className="label-comment">*</font>
                      </label>
                      <select
                        type="text"
                        name="residence"
                        value={this.state.residence}
                        onChange={this.handleInputState}
                        className="custom-select custom-select-md"
                        aria-readonly="true"
                      >
                        <option key="" value="">
                          -- Select country of residence --{" "}
                        </option>
                        {this.props.countries.map((options) => (
                          <option key={options.id} value={options.country}>
                            {options.country}
                          </option>
                        ))}
                      </select>
                      {this.state.errors.residence ? (
                        <div className="errorMsg">
                          {this.state.errors.residence}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {this.state.showRegion === true ? (
                  <>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-sm-6">
                          <label className="label-class">
                            Region
                            <font className="label-comment">*</font>
                          </label>
                          <select
                            type="text"
                            name="tzregion"
                            value={this.state.tzregion}
                            onChange={this.handleInputState}
                            className="custom-select custom-select-md"
                            aria-readonly="true"
                          >
                            <option key="" value="">
                              -- Select Region --{" "}
                            </option>
                            {this.props.regions.map((options) => (
                              <option
                                key={options.id}
                                value={
                                  options.id +
                                  "," +
                                  toTitleconverttoTitle(options.region)
                                }
                              >
                                {toTitleconverttoTitle(options.region)}
                              </option>
                            ))}
                          </select>
                          {this.state.errors.tzregion ? (
                            <div className="errorMsg">
                              {this.state.errors.tzregion}
                            </div>
                          ) : null}
                        </div>

                        {this.state.showDistrict === true ? (
                          <div className="col-sm-6">
                            {this.state.district_loadig === true ? (
                              <ReactLoading type="cylon" color="#000" />
                            ) : (
                              <>
                                <label className="label-class">
                                  District
                                  <font className="label-comment">*</font>
                                </label>
                                <select
                                  type="text"
                                  name="tzdistrict"
                                  value={this.state.tzdistrict}
                                  onChange={this.handleInputState}
                                  className="custom-select custom-select-md"
                                  aria-readonly="true"
                                >
                                  <option key="" value="">
                                    -- Select District --{" "}
                                  </option>
                                  {this.state.districts.map((options) => (
                                    <option
                                      key={options.id}
                                      value={toTitleconverttoTitle(
                                        options.district
                                      )}
                                    >
                                      {toTitleconverttoTitle(options.district)}
                                    </option>
                                  ))}
                                </select>
                                {this.state.errors.tzdistrict ? (
                                  <div className="errorMsg">
                                    {this.state.errors.tzdistrict}
                                  </div>
                                ) : null}
                              </>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {this.state.showRegion === false ? (
                      <>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6">
                              <label className="label-class">
                                Region
                                <font className="label-comment">*</font>
                              </label>
                              <input
                                type="text"
                                name="region"
                                value={this.state.region}
                                onChange={this.handleInputState}
                                className="form-control"
                                placeholder="Region"
                              />
                              {this.state.errors.region ? (
                                <div className="errorMsg">
                                  {this.state.errors.region}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-sm-6">
                              <label className="label-class">
                                District
                                <font className="label-comment">*</font>
                              </label>
                              <input
                                type="text"
                                name="district"
                                value={this.state.district}
                                onChange={this.handleInputState}
                                className="form-control"
                                placeholder="District"
                              />
                              {this.state.errors.district ? (
                                <div className="errorMsg">
                                  {this.state.errors.district}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                )}
                {/* </fieldset> */}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
              >
                <FaUpload />
                {"     "}Save
              </Button>
              <Button
                variant="secondary"
                className="btn btn-round  btn-sm btn btn-round"
                onClick={this.closeModal}
              >
                Close
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }
}
const AddKin_2 = withRouter(AddKin);
export default AddKin_2;
