import React, { Component } from "react";
import { Container } from "reactstrap";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import ReactLoading from "react-loading";
import ApplicationInstruction from "./ApplicationInstruction";
import AddApplyingProgram from "./addApplyingPrograms";
import ApplyingProgramDisplay from "./displayApplyingPrograms";
import Alert from "@mui/material/Alert";
class ApplyingProgram extends Component {
  state = {
    programs_data: [],
    campus: [],
    info_loading: null,
    network_error: false,
    filling_arrow: "",
    stage: parseInt(localStorage.getItem("stageid")),
  };
  componentDidMount() {
    this.getApplyingProgram();
    this.getCampus();
  }

  getCampus = async () => {
    this.setState({ campus_loading: true });
    await api
      .get(params.CAMPUS_LIST)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res.data.listdata);
          if (res.data.stsCode === "0000") {
            this.setState({
              campus: res.data.listdata,
              campus_loading: false,
              info_loading: false,
              network_error: false,
            });
          }
        } else {
          this.setState({
            campus: [],
            campus_loading: false,
            network_error: true,
            program_loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
          program_loading: false,
        });
      });
  };
  getApplyingProgram = () => {
    this.setState({ info_loading: true });
    api
      .get(params.GET_APPLIED_PROGRAM + parseInt(localStorage.getItem("id")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              programs_data: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            programs_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Applying Programs
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        <ReactLoading type="cylon" color="#000" />
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <>
                          <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <div className="alert alert-warning">
                                <strong>Warning!</strong> You are facing a
                                connection problem, please contact the system
                                administrator OR try later
                              </div>
                            </div>
                            <div className="col-sm-1"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.programs_data.length > 0 ? (
                            <>
                              <div className="row">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10">
                                  {" "}
                                  <ApplyingProgramDisplay
                                    programs_data={this.state.programs_data}
                                  />
                                </div>
                                <div className="col-sm-1"></div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.stage < 7 ? (
                                <>
                                  {" "}
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <Alert severity="warning">
                                        To activate this stage. Please fill the
                                        information in previous stages
                                      </Alert>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-10">
                                      <ApplicationInstruction
                                        status={this.state.filling_arrow}
                                      />
                                    </div>
                                    <div className="col-sm-1"></div>
                                  </div>

                                  <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-10">
                                      <AddApplyingProgram
                                        campus={this.state.campus}
                                      />
                                    </div>
                                    <div className="col-sm-1"></div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default ApplyingProgram;
