import React, { Component } from "react";
import { FaRegHandPointRight } from "react-icons/fa";

class UnconfirmInstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    return (
      <>
        <p>
          <b>Instructions</b>
        </p>
        <hr />

        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>1.</span>
            <span style={{ color: "green", fontWeight: "bold" }}>
              Congratulation
            </span>
            , You have been selected to TIA in the programme{" "}
            <span style={{ fontWeight: "bold" }}> "{this.props.program}"</span>{" "}
            in <span style={{ fontWeight: "bold" }}>"{this.props.campus}"</span>{" "}
            Campus,
            {""}
            <span style={{ fontWeight: "bold" }}>
              You have already confirmed. You have single selection now.
            </span>
            {/* <li>
              {" "}
              <span style={{ fontWeight: "bold" }}>2.</span> To unconfirm,
              request unconfirmation code from TCU.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>3.</span> Once you receive
              the unconfirmation code in your mobile, click unconfirm button to
              submit it.
            </li> */}
            <li>
              <span style={{ fontWeight: "bold" }}>2.</span> Use the same
              confirmation code you received earlier to unconfirm
            </li>
          </li>
          <li>
            <FaRegHandPointRight size={25} />
          </li>
        </ul>
      </>
    );
  }
}
export default UnconfirmInstruction;
