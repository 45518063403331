import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import ReactLoading from "react-loading";
import { useHistroy } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { FaHandPointRight } from "react-icons/fa";
import params from "../../util/api_routes/params";
import api from "../../util/api";

class Submission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_loading: null,
      network_error: false,
      college_data: [],
      stage: parseInt(localStorage.getItem("stageid")),
      submit_application_data: [],
    };
  }

  componentDidMount() {
    this.getCollegeList();
  }

  changePrograme = async (e) => {
    e.preventDefault();
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };

    await api
      .post(params.CHANGE_PROGRAMME, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              new_stage: localStorage.setItem("stageid", 7),
              submit_application: res.data.data,
              loading: false,
              network_error: false,
            });

            // window.location.reload();
            this.props.history.push({
              state: {
                submit_application_data: this.state.submit_application,
              },
              pathname: "/applying-programs/applying-programs",
            });
          } else {
            this.setState({
              network_error: true,
              loading: false,
            });
            // console.log(parseInt(localStorage.getItem("stageid")));
          }
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, loading: false });
        // console.log(parseInt(localStorage.getItem("stageid")));
      });
  };

  getCollegeList = async () => {
    this.setState({ info_loading: true });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      awardId: parseInt(localStorage.getItem("awardId")),
    };
    await api
      .post(params.GET_COLLEGE_LIST, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              college_data: res.data.listdata,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            college_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  render() {
    // console.log(parseInt(localStorage.getItem("awardId")));
    // console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  {/* <i className="fas fa-trophy"></i> */}
                  {/* {""}Your application have been submitted successfully */}
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <>
                      {" "}
                      <ReactLoading type="cylon" color="#000" />
                    </>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-warning">
                              <strong>Warning!</strong> You are facing a
                              connection problem, please contact the system
                              administrator OR try later
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-sm-12">
                              {}
                              {/* {console.log(
                                this.props.location.state
                                  .submit_application_data
                              )} */}

                              {/* demarcation of postgraduate and masters students*/}

                              {parseInt(localStorage.getItem("awardId")) !==
                                4 &&
                              parseInt(localStorage.getItem("awardId")) !==
                                5 ? (
                                <>
                                  {/* {parseInt(localStorage.getItem("awardId")) ===
                                    2 && this.state.college_data.length > 0 ? ( */}
                                  {(parseInt(
                                    localStorage.getItem("awardId")
                                  ) === 2 ||
                                    parseInt(
                                      localStorage.getItem("awardId")
                                    ) === 1) &&
                                  this.state.college_data.length > 0 ? (
                                    <>
                                      <Alert severity="success">
                                        Please wait for final selection
                                      </Alert>
                                    </>
                                  ) : (
                                    <>
                                      {this.props.location.state
                                        .submit_application_data.isQualified ===
                                      1 ? (
                                        <>
                                          <h5>
                                            <Alert severity="success">
                                              Congrats!!! You have met minimum
                                              entry requirement, wait for final
                                              selection.
                                            </Alert>
                                          </h5>
                                        </>
                                      ) : (
                                        <>
                                          {/* <div className="row">
                                    <div className="col-sm-1"></div>
                                    <div className="col-sm-10"> */}
                                          <h5>
                                            <Alert severity="warning">
                                              Sorry!!! You have not met minimum
                                              entry requirement.
                                            </Alert>
                                          </h5>

                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    backgroundColor: "#f1f5f7",
                                                    fontWeight: "bold",
                                                    fontFamily: "Helvetica",
                                                    fontSize: "13px",
                                                  }}
                                                  colSpan="7"
                                                >
                                                  REASONS:
                                                </th>
                                              </tr>
                                              <tr>
                                                <th
                                                  style={{
                                                    backgroundColor: "#f1f5f7",
                                                    fontWeight: "bold",
                                                    fontFamily: "Helvetica",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  CAMPUS
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor: "#f1f5f7",
                                                    fontWeight: "bold",
                                                    fontFamily: "Helvetica",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  CHOICE
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor: "#f1f5f7",
                                                    fontWeight: "bold",
                                                    fontFamily: "Helvetica",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  PROGRAMME
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor: "#f1f5f7",
                                                    fontWeight: "bold",
                                                    fontFamily: "Helvetica",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  REASON
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.props.location.state
                                                .submit_application_data
                                                .reasons ? (
                                                <>
                                                  {this.props.location.state.submit_application_data.reasons.map(
                                                    (option, i) => (
                                                      <tr key={i}>
                                                        <td
                                                          style={{
                                                            fontStyle: "italic",
                                                          }}
                                                        >
                                                          {" "}
                                                          {option.campus}
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {option.choice}
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {option.program}
                                                        </td>
                                                        <td>
                                                          {" "}
                                                          {option.reason}
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                            </tbody>
                                          </table>
                                          {/* </div>
                                    <div className="col-sm-1"></div>
                                  </div> */}
                                          <div
                                            style={{
                                              color: "red",
                                              fontSize: "17px",
                                            }}
                                          >
                                            {/* <Link
                                      onClick={this.changePrograme}
                                      style={{
                                        color: "red",
                                        fontSize: "17px",
                                      }}
                                    > */}
                                            <ul>
                                              <li>
                                                {" "}
                                                <FaHandPointRight />
                                                Click{" "}
                                                <Link>
                                                  <span
                                                    style={{
                                                      color: "green",
                                                      fontSize: "18px",
                                                    }}
                                                    onClick={
                                                      this.changePrograme
                                                    }
                                                  >
                                                    Here
                                                  </span>{" "}
                                                </Link>
                                                to re-apply to other programmes
                                                other than the ones mentioned in
                                                the above table.
                                              </li>
                                            </ul>
                                            {/* </Link> */}
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Alert severity="success">
                                    Please wait for final selection
                                  </Alert>
                                  {/* <ul>
                                    <li>
                                      {" "}
                                      <FaHandPointRight />
                                      Click{" "}
                                      <Link>
                                        <span
                                          style={{
                                            color: "green",
                                            fontSize: "18px",
                                          }}
                                          onClick={this.changePrograme}
                                        >
                                          Here
                                        </span>{" "}
                                      </Link>
                                      to re-apply to other programmes other than
                                      the ones mentioned in the above table.
                                    </li>
                                  </ul> */}
                                </>
                              )}

                              {/* demarcation of postgraduate and masters students */}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default Submission;
