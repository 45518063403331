import axios from "api/axios";

const GET_REFERENCE = "/api/permit-info";
const DELETE_COLLEGE_INFO = "/api/college/delete/";
const VIEW = "/api/permit-info/preview";

const getReferenceDetails = async (params) => {
  return await axios.get(`${GET_REFERENCE}`, { params });
};

const deleteCollegeInfo = async (id) => {
  return await axios.delete(`${DELETE_COLLEGE_INFO}${id}`);
};

const viewReferenceDetails = async (params) => {
  return await axios.get(`${VIEW}`, { params });
};

export { getReferenceDetails, deleteCollegeInfo, viewReferenceDetails };
