import React, { Component } from "react";
import { Button, Container } from "react-bootstrap";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import ReactLoading from "react-loading";
import AddKin from "./AddKin";
import KinInstruction from "./KinInstruction";
import changeStage from "../../util/changeStage";
import {
  FaArrowAltCircleRight,
  FaUpload,
  FaRegEdit,
  FaRegHandPointRight,
} from "react-icons/fa";
import Alert from "@mui/material/Alert";
import { Modal } from "react-bootstrap";
import toTitleconverttoTitle from "../../util/toTitleConveter";
import tzPhoneNumberValidator from "../../util/tzPhoneNumberValidator";

class NextOfKin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextofkindata: [],
      info_loading: null,
      network_error: false,
      stage: parseInt(localStorage.getItem("stageid")),
      filling_arrow: "",
      countries: [],
      regions: [],
      districts: [],
      stage_next: "",
      isOpen: false,
      fields: {},
      errors: {},
      tzdistrict: "",
      region: "",
      district: "",
      full_name: "",
      address: "",
      phone: "",
      country: "",
      district: "",
      residence: "",
      region: "",
      relationship: "",
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    if (e.target.name === "residence") {
      if (e.target.value === "") {
        this.setState({ showRegion: "" });
      }
      if (e.target.value === "Tanzania") {
        this.setState({ showRegion: true });
      }
      if (e.target.value !== "Tanzania" && e.target.value !== "") {
        this.setState({ showRegion: false });
      }
    }
    if (e.target.name === "tzregion") {
      this.setState({ showDistrict: true });
      if (e.target.value === "") {
        this.setState({ district_loadig: false });
      }
      if (e.target.value !== "") {
        this.setState({ districts: [] });
        const id = e.target.value.split(",");
        this.getDistrict(parseInt(id[0]));
        this.setState({ region: id[1] });
      }
    }
  };

  openModal = () => this.setState({ isOpen: true, errors: "" });
  closeModal = () => this.setState({ isOpen: false, errors: "" });

  componentDidMount() {
    this.getNextofKinInformation();
    this.getCountries();
    this.getRegions();
    this.getDistrict();
  }
  getRegions = async () => {
    try {
      api
        .get(params.REGION_LIST)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                regions: res.data.data,
              });
            }
            if (res.data.stsCode === "0001") {
              this.setState({ applicationProgramme: null });
            }
          } else {
            this.setState({ serverError: true });
          }
        })
        .catch((err) => {
          this.setState({ serverError: true });
        });
    } catch (error) {
      this.setState({ serverError: true });
    }
  };

  getDistrict = async (id) => {
    this.setState({ district_loadig: true });
    try {
      await api
        .get(params.DISTRICT_LIST + "/" + id)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                districts: res.data.data,
                district_loadig: false,
              });
            }
          } else {
            this.setState({});
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  getCountries = async () => {
    try {
      await api
        .get(params.COUNTRY_LIST)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                countries: res.data.data,
              });
            }
            if (res.data.stsCode === "0001") {
              this.setState({ applicationProgramme: null });
            }
          } else {
            this.setState({ serverError: true });
          }
        })
        .catch((err) => {
          this.setState({ serverError: true });
        });
    } catch (error) {
      this.setState({ serverError: true });
    }
  };

  getNextofKinInformation = async () => {
    this.setState({
      info_loading: true,
    });
    await api
      .get(params.NEXT_OF_KIN_LIST + localStorage.getItem("id"))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              nextofkindata: res.data.data,
              fullname: res.data.data.fullname,
              country: res.data.data.country,
              district: res.data.data.district,
              phone: res.data.data.cellPhone,
              address: res.data.data.postalBox,
              region: res.data.data.region,
              relationship: res.data.data.relationship,
              id: res.data.data.id,
              applicationProfileId: res.data.data.applicationProfileId,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
          // console.log(res.data);
        } else {
          this.setState({
            nextofkindata: [],
            info_loading: false,
            network_error: true,
            filling_arrow: "right",
          });
        }
      })
      .catch((error) => {
        this.setState({ network_error: true, info_loading: false });
      });
  };

  // Edit next of Kin Information
  submitEditedNextOfKinInfo = async (e) => {
    e.preventDefault();
    if (this.validateEditForm()) {
      console.log("huree");
      let payLoad = {
        id: this.state.id,
        fullname: this.state.fullname,
        relationship: this.state.relationship,
        applicationProfileId: parseInt(localStorage.getItem("id")),
        cellPhone: this.state.phone,
        country: this.state.residence,
        region:
          this.state.region !== "" ? this.state.region : this.state.tzregion,
        district:
          this.state.district !== ""
            ? this.state.district
            : this.state.tzdistrict,
        postalBox: this.state.address,
      };
      // let payLoad_2 = {
      //   applicationProfileId: parseInt(localStorage.getItem("id")),
      // };
      // let result = await changeStage(payLoad_2);
      await api
        .post(params.EDIT_NEXTOFKIN, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                yesIsOpen: false,
                noIsOpen: false,
                loading: false,
                redirect: true,
                full_name: "",
                network_error: false,
              });
              // this.props.history.push("/personal/nextofkin");
              window.location.reload();
            } else {
              this.setState({
                network_error: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ network_error: true, loading: false });
        });
    }
  };

  ///Form validation
  validateEditForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    const valuet = this.state.fullname;

    if (!this.state.relationship) {
      formIsValid = false;
      errors["relationship"] = "* Please select the next of kin.";
    }
    if (!this.state.fullname || !isNaN(+valuet)) {
      formIsValid = false;
      errors["fullname"] = "* Please fill in full name in text";
    }

    if (!this.state.address) {
      formIsValid = false;
      errors["address"] = "* Please fill in address.";
    }

    if (tzPhoneNumberValidator(this.state.phone) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["citizenship"] || typeof fields["citizenship"] === undefined) {
      formIsValid = false;
      errors["citizenship"] = "* Please select citizenship.";
    }
    if (!fields["residence"] || typeof fields["residence"] === undefined) {
      formIsValid = false;
      errors["residence"] = "* Please select country of residency.";
    }
    if (fields["residence"] === "Tanzania") {
      if (!fields["tzregion"] || typeof fields["tzregion"] === undefined) {
        formIsValid = false;
        errors["tzregion"] = "* Please select region.";
      }
      if (!fields["tzdistrict"] || typeof fields["tzdistrict"] === undefined) {
        formIsValid = false;
        errors["tzdistrict"] = "* Please select district.";
      }
    }
    if (fields["residence"] !== "Tanzania") {
      if (!fields["region"] || typeof fields["region"] === undefined) {
        formIsValid = false;
        errors["region"] = "* Please fill the  region.";
      }
      if (!fields["district"] || typeof fields["district"] === undefined) {
        formIsValid = false;
        errors["district"] = "* Please fill the  district.";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  nextToConfirmationSubmission = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 3,
    };
    let result = await changeStage(payLoad);
    if (result === 1) {
      this.setState({ loading: false, network_error: false });
      this.props.history.push("/education/primary");
    } else {
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Next Of Kin (Close Relative)
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <>
                      {" "}
                      <ReactLoading type="cylon" color="#000" />
                    </>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <div className="row">
                          <div className="col-sm-1"></div>
                          <div className="col-sm-10">
                            <div className="alert alert-warning">
                              <strong>Warning!</strong> You are facing a
                              connection problem, please contact the system
                              administrator OR try later
                            </div>
                          </div>
                          <div className="col-sm-1"></div>
                        </div>
                      ) : (
                        <>
                          {/* Object.keys(this.state.nextofkindata).length > 0 */}

                          {this.state.nextofkindata.fullname != null ? (
                            <>
                              <div className="row">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-10">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "13px",
                                          }}
                                          colSpan="7"
                                        >
                                          NEXT OF KIN INFORMATION
                                        </th>
                                      </tr>
                                      <tr>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "12px",
                                          }}
                                        >
                                          FULL NAME
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "12px",
                                          }}
                                        >
                                          RELATIONSHIP
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "13px",
                                          }}
                                        >
                                          COUNTRY
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "13px",
                                          }}
                                        >
                                          REGION
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "13px",
                                          }}
                                        >
                                          DISTRICT
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "13px",
                                          }}
                                        >
                                          CELL PHONE
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor: "#f1f5f7",
                                            fontWeight: "bold",
                                            fontFamily: "Helvetica",
                                            fontSize: "13px",
                                          }}
                                        >
                                          ADDRESS
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{this.state.fullname}</td>
                                        <td>{this.state.relationship}</td>
                                        <td> {this.state.country}</td>
                                        <td> {this.state.region}</td>
                                        <td> {this.state.district}</td>
                                        <td> {this.state.phone}</td>
                                        <td>
                                          {" "}
                                          {this.state.address}
                                          {console.log(this.state.stage)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="col-sm-1"></div>
                              </div>

                              {/* Edit next of Kin */}

                              <div>
                                <Modal
                                  size="lg"
                                  show={this.state.isOpen}
                                  onHide={this.closeModal}
                                >
                                  <Modal.Header closeButton>
                                    <div className="float-center card-header-title">
                                      <FaRegEdit />
                                      Next of Kin Information
                                    </div>
                                  </Modal.Header>
                                  <form
                                    onSubmit={this.submitEditedNextOfKinInfo}
                                  >
                                    <Modal.Body>
                                      <div className="form-group">
                                        <label>
                                          {" "}
                                          Select Next of Kin (Close Relative)
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        <select
                                          name="relationship"
                                          className="selection-box  input-placeholder"
                                          value={this.state.relationship}
                                          onChange={this.handleInputState}
                                        >
                                          <option key={0} value="">
                                            {" "}
                                            -- Select the relative --{" "}
                                          </option>
                                          <option key={1} value="Father">
                                            Father
                                          </option>
                                          <option key={2} value="Mother">
                                            Mother
                                          </option>
                                          <option key={3} value="Uncle">
                                            Uncle
                                          </option>
                                          <option key={4} value="Aunt">
                                            Aunt
                                          </option>
                                          <option key={5} value="Brother">
                                            Brother
                                          </option>
                                          <option key={6} value="Sister">
                                            Sister
                                          </option>
                                          <option key={6} value="Guardian">
                                            Guardian
                                          </option>
                                        </select>
                                        {this.state.errors.relationship ? (
                                          <div className="errorMsg">
                                            {this.state.errors.relationship}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Next Of Kin Full Name
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        <input
                                          name="fullname"
                                          className="form-control"
                                          value={this.state.fullname}
                                          onChange={this.handleInputState}
                                        ></input>
                                        {this.state.errors.fullname ? (
                                          <div className="errorMsg">
                                            {this.state.errors.fullname}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Phone Number
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        <input
                                          type="number"
                                          name="phone"
                                          className="form-control input-placeholder"
                                          value={this.state.phone}
                                          onChange={this.handleInputState}
                                          placeholder="Phone"
                                        ></input>
                                        {this.state.errors.phone ? (
                                          <div className="errorMsg">
                                            {this.state.errors.phone}
                                          </div>
                                        ) : null}
                                      </div>

                                      <div className="form-group">
                                        <label>
                                          Contact Address
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        <input
                                          type="text"
                                          name="address"
                                          className="form-control input-placeholder"
                                          value={this.state.address}
                                          onChange={this.handleInputState}
                                          placeholder="Contact Address"
                                        ></input>
                                        {this.state.errors.address ? (
                                          <div className="errorMsg">
                                            {this.state.errors.address}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="form-group">
                                        <label>
                                          Residence
                                          <font className="label-comment">
                                            *
                                          </font>
                                        </label>
                                        {/* <fieldset className="scheduler-border"> */}
                                        <div className="form-group">
                                          <div className="row">
                                            <div className="col-sm-6">
                                              <label className="label-class">
                                                Citizeship
                                                <font className="label-comment">
                                                  *
                                                </font>
                                              </label>
                                              <select
                                                type="text"
                                                name="citizenship"
                                                value={this.state.citizenship}
                                                onChange={this.handleInputState}
                                                className="custom-select custom-select-md"
                                                aria-readonly="true"
                                              >
                                                <option key="" value="">
                                                  -- Select citizenship --{" "}
                                                </option>
                                                {this.state.countries.map(
                                                  (options) => (
                                                    <option
                                                      key={options.id}
                                                      value={
                                                        options.citizenship
                                                      }
                                                    >
                                                      {options.citizenship}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {this.state.errors.citizenship ? (
                                                <div className="errorMsg">
                                                  {
                                                    this.state.errors
                                                      .citizenship
                                                  }
                                                </div>
                                              ) : null}
                                            </div>
                                            <div className="col-sm-6">
                                              <label className="label-class">
                                                Country of Residence
                                                <font className="label-comment">
                                                  *
                                                </font>
                                              </label>
                                              <select
                                                type="text"
                                                name="residence"
                                                value={this.state.residence}
                                                onChange={this.handleInputState}
                                                className="custom-select custom-select-md"
                                                aria-readonly="true"
                                              >
                                                <option key="" value="">
                                                  -- Select country of residence
                                                  --{" "}
                                                </option>
                                                {this.state.countries.map(
                                                  (options) => (
                                                    <option
                                                      key={options.id}
                                                      value={options.country}
                                                    >
                                                      {options.country}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              {this.state.errors.residence ? (
                                                <div className="errorMsg">
                                                  {this.state.errors.residence}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>

                                        {this.state.showRegion === true ? (
                                          <>
                                            <div className="form-group">
                                              <div className="row">
                                                <div className="col-sm-6">
                                                  <label className="label-class">
                                                    Region
                                                    <font className="label-comment">
                                                      *
                                                    </font>
                                                  </label>
                                                  <select
                                                    type="text"
                                                    name="tzregion"
                                                    value={this.state.tzregion}
                                                    onChange={
                                                      this.handleInputState
                                                    }
                                                    className="custom-select custom-select-md"
                                                    aria-readonly="true"
                                                  >
                                                    <option key="" value="">
                                                      -- Select Region --{" "}
                                                    </option>
                                                    {this.state.regions.map(
                                                      (options) => (
                                                        <option
                                                          key={options.id}
                                                          value={
                                                            options.id +
                                                            "," +
                                                            toTitleconverttoTitle(
                                                              options.region
                                                            )
                                                          }
                                                        >
                                                          {toTitleconverttoTitle(
                                                            options.region
                                                          )}
                                                        </option>
                                                      )
                                                    )}
                                                  </select>
                                                  {this.state.errors
                                                    .tzregion ? (
                                                    <div className="errorMsg">
                                                      {
                                                        this.state.errors
                                                          .tzregion
                                                      }
                                                    </div>
                                                  ) : null}
                                                </div>

                                                {this.state.showDistrict ===
                                                true ? (
                                                  <div className="col-sm-6">
                                                    {this.state
                                                      .district_loadig ===
                                                    true ? (
                                                      <ReactLoading
                                                        type="cylon"
                                                        color="#000"
                                                      />
                                                    ) : (
                                                      <>
                                                        <label className="label-class">
                                                          District
                                                          <font className="label-comment">
                                                            *
                                                          </font>
                                                        </label>
                                                        <select
                                                          type="text"
                                                          name="tzdistrict"
                                                          value={
                                                            this.state
                                                              .tzdistrict
                                                          }
                                                          onChange={
                                                            this
                                                              .handleInputState
                                                          }
                                                          className="custom-select custom-select-md"
                                                          aria-readonly="true"
                                                        >
                                                          <option
                                                            key=""
                                                            value=""
                                                          >
                                                            -- Select District
                                                            --{" "}
                                                          </option>
                                                          {this.state.districts.map(
                                                            (options) => (
                                                              <option
                                                                key={options.id}
                                                                value={toTitleconverttoTitle(
                                                                  options.district
                                                                )}
                                                              >
                                                                {toTitleconverttoTitle(
                                                                  options.district
                                                                )}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                        {this.state.errors
                                                          .tzdistrict ? (
                                                          <div className="errorMsg">
                                                            {
                                                              this.state.errors
                                                                .tzdistrict
                                                            }
                                                          </div>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            {this.state.showRegion === false ? (
                                              <>
                                                <div className="form-group">
                                                  <div className="row">
                                                    <div className="col-sm-6">
                                                      <label className="label-class">
                                                        Region
                                                        <font className="label-comment">
                                                          *
                                                        </font>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        name="region"
                                                        value={
                                                          this.state.region
                                                        }
                                                        onChange={
                                                          this.handleInputState
                                                        }
                                                        className="form-control"
                                                        placeholder="Region"
                                                      />
                                                      {this.state.errors
                                                        .region ? (
                                                        <div className="errorMsg">
                                                          {
                                                            this.state.errors
                                                              .region
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </div>

                                                    <div className="col-sm-6">
                                                      <label className="label-class">
                                                        District
                                                        <font className="label-comment">
                                                          *
                                                        </font>
                                                      </label>
                                                      <input
                                                        type="text"
                                                        name="district"
                                                        value={
                                                          this.state.district
                                                        }
                                                        onChange={
                                                          this.handleInputState
                                                        }
                                                        className="form-control"
                                                        placeholder="District"
                                                      />
                                                      {this.state.errors
                                                        .district ? (
                                                        <div className="errorMsg">
                                                          {
                                                            this.state.errors
                                                              .district
                                                          }
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                        {/* </fieldset> */}
                                      </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        variant="primary"
                                        type="submit"
                                        className="btn btn-round  btn-sm"
                                      >
                                        <FaUpload />
                                        {"     "}Save
                                      </Button>
                                      <Button
                                        variant="secondary"
                                        className="btn btn-round  btn-sm btn btn-round"
                                        onClick={this.closeModal}
                                      >
                                        Close
                                      </Button>
                                    </Modal.Footer>
                                  </form>
                                </Modal>
                              </div>
                              <div className="col-sm-1">
                                <div style={{ paddingTop: "36px" }}>
                                  {Object.keys(this.state.nextofkindata)
                                    .length > 0 ? (
                                    <Button
                                      variant="warning"
                                      onClick={this.openModal}
                                      className="btn btn-round  btn-sm btn-warning verification-button"
                                    >
                                      <i className="fa fa-edit fa-sm"></i> Edit
                                    </Button>
                                  ) : null}
                                </div>
                              </div>
                              {/* End Edit next of Kin */}

                              <br></br>
                              <div className="row">
                                <div className="col-sm-1"></div>
                                <div className="col-sm-11">
                                  {this.state.stage === 2 ? (
                                    <>
                                      <Button
                                        type="submit"
                                        className="btn btn-round  btn-md"
                                        variant="primary"
                                        onClick={this.nextToConfirmationSubmission.bind(
                                          this
                                        )}
                                      >
                                        Continue to Primary Education{" "}
                                        <FaArrowAltCircleRight size={20} />
                                      </Button>
                                    </>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.stage < 2 ? (
                                <>
                                  {" "}
                                  <Alert severity="warning">
                                    Please make payment first
                                    {/* {console.log(this.state.stage)} */}
                                  </Alert>
                                </>
                              ) : (
                                <>
                                  <div className="row">
                                    <div className="col-sm-1">
                                      {/*Display steps*/}
                                    </div>
                                    <div className="col-sm-5">
                                      <KinInstruction
                                      // status={this.state.filling_arrow}
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <AddKin
                                        countries={this.state.countries}
                                        regions={this.state.regions}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default NextOfKin;
