import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaRegSmile, FaUpload } from "react-icons/fa";
import Alert from "@mui/material/Alert";
import ReactLoading from "react-loading";
import params from "../../util/api_routes/params";
import api from "../../util/api";
import Accordion from "react-bootstrap/Accordion";
import changeStage from "../../util/changeStage";
// import { withRouter } from "react-router";
import { withRouter } from "react-router-dom";
import toTitleconverttoTitle from "../../util/toTitleConveter";

class PerformDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      declare: "",
      yesIsOpen: false,
      noIsOpen: false,
      redirect: false,
      level: null,
      isSelected: false,
      loading: "",
      fields: {},
      errors: {},
      id: null,
      first_name: "",
      last_name: "",
      middle_name: "",
      sex: "",
      dob: "",
      citizenship: "",
      countryOfResident: "",
      region: "",
      district: "",
      cellPhone: "",
      email: "",
      info_loading: null,
      network_error: false,
      school: "",
      primary_school: "",
      year: "",
      application_id: "",
      networkError: "",
      addingError: "",
      apiError: "",
      next_0f_kin_data: [],
      fullname: "",
      secondary_school: [],
      high_school_data: [],
      programs_data: [],
      declaration: "",
      submit_application: [],
      submit_application_data: [],
      stage: "",
      college_data: [],
    };
  }

  handleCheckBox = (e) => this.setState({ declaration: e.target.checked });

  componentDidMount() {
    this.getPrimaryEducation();
    this.getNextofKinInformation();
    this.getCollegeList();
  }

  getNextofKinInformation = async () => {
    this.setState({
      info_loading: true,
    });
    await api
      .get(params.NEXT_OF_KIN_LIST + localStorage.getItem("id"))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              next_0f_kin_data: res.data.data,
              fullname: res.data.data.fullname,
              cellPhone: res.data.data.cellPhone,
              country: res.data.data.country,
              district: res.data.data.district,
              postalBox: res.data.data.postalBox,
              region: res.data.data.region,
              relationship: res.data.data.relationship,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            next_0f_kin_data: [],
            info_loading: false,
            network_error: true,
            filling_arrow: "right",
          });
        }
      })
      .catch((error) => {
        this.setState({ network_error: true, info_loading: false });
      });
  };

  componentWillReceiveProps(props) {
    this.setState({
      first_name: props.user_data.firstname,
      middle_name: props.user_data.middlename,
      last_name: props.user_data.surname,
      dob: props.user_data.dob,
      cellPhone: props.user_data.cellPhone,
      citizenship: props.user_data.citizenship,
      countryOfResident: props.user_data.countryOfResident,
      region: props.user_data.region,
      district: props.user_data.district,
      email: props.user_data.applicationProfile.email,
      accademic_year: props.user_data.applicationProfile.academicYear,
      award: props.user_data.applicationProfile.award.award,
      sex: props.user_data.sex,
    });
  }
  openModelForm = (e) => {
    e.preventDefault();
    if (this.state.declaration) {
      this.setState({ yesIsOpen: true });
      this.setState({ noIsOpen: false });
    } else {
      alert("Please check the box");
    }
  };
  closeModal = () => {
    this.setState({
      yesIsOpen: false,
      noIsOpen: false,
      loading: "",
      fields: {},
      errors: {},
      network_error: false,
      particular_exists: false,
      selectedFile: "",
      setIsSelected: false,
      isSelected: false,
    });
  };
  validateQuestion = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["declare"]) {
      formIsValid = false;
      errors["relative"] = "* Please Preview.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  getPreviewInformation = async () => {
    this.setState({
      info_loading: true,
    });
  };

  getPrimaryEducation = async () => {
    await api
      .get(
        params.PRIMARY_EDUCATION + "/" + parseInt(localStorage.getItem("id"))
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              primary_school: res.data.data,
              application_id: res.data.data.profileId,
              school: res.data.data.schoolName,
              year: Number(res.data.data.yearCompleted),
              networkError: false,
              addingError: false,
              apiError: false,
            });
          }
          if (res.data.stsCode === "0003") {
            this.setState({
              primary_school: [],
              networkError: false,
              apiError: false,
            });
          }
        } else {
          this.setState({ networkError: true });
        }
      })
      .catch((err) => {
        this.setState({ apiError: true });
      });
  };

  getCollegeList = async () => {
    this.setState({ info_loading: true });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      awardId: parseInt(localStorage.getItem("awardId")),
    };
    await api
      .post(params.GET_COLLEGE_LIST, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              college_data: res.data.listdata,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            college_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };

  submitDeclaration = async (e) => {
    e.preventDefault();

    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };

    let payLoadStageId = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 9,
    };

    let payLoadTcuAddApplicant = {
      admno: parseInt(localStorage.getItem("id")),
    };

    // if (
    //   parseInt(localStorage.getItem("stageid")) === 8 &&
    //   (parseInt(localStorage.getItem("awardId")) === 1 ||
    //     (parseInt(localStorage.getItem("awardId")) === 2 &&
    //       this.state.college_data.length === 0) ||
    //     parseInt(localStorage.getItem("awardId")) === 3)
    // ) {
    console.log(this.state.college_data.length);

    if (
      parseInt(localStorage.getItem("stageid")) === 8 &&
      ((parseInt(localStorage.getItem("awardId")) === 1 &&
        this.state.college_data.length === 0) ||
        (parseInt(localStorage.getItem("awardId")) === 2 &&
          this.state.college_data.length === 0) ||
        parseInt(localStorage.getItem("awardId")) === 3)
    ) {
      await api
        .post(params.SUBMIT_APPLICATION, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                submit_application: res.data.data,
                loading: false,
                network_error: false,
                stage: localStorage.setItem("stageid", 9),
              });
              // console.log("nimefika");
              this.props.history.push({
                state: {
                  submit_application_data: this.state.submit_application,
                },
                pathname: "/after/submission",
              });
            } else {
              this.setState({
                network_error: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ network_error: true, loading: false });
        });
    } else {
      if (parseInt(localStorage.getItem("stageid")) === 8) {
        let result = await changeStage(payLoadStageId);
        if (result === 1) {
          this.props.history.push({
            state: {
              submit_application_data: [],
            },
            pathname: "/after/submission",
          });
        } else {
        }
      }
      // console.log("nimefika_2");
    }

    if (parseInt(localStorage.getItem("awardId")) === 3) {
      await api
        .post(params.SUBMIT_APPLICANT_TCU, payLoadTcuAddApplicant)
        .then((res) => {
          if (res.status === 200) {
            // if (res.data.stsCode === "0000") {
            // } else {
            // }
            console.log(res.status);
          }
        })
        .catch((err) => {});
    }
  };

  render() {
    // console.log(parseInt(localStorage.getItem("stageid")));
    let count = 1;
    return (
      <div className="topping">
        <fieldset className="scheduler-border">
          <legend className="scheduler-border">
            Check the box and preview your application
          </legend>
          <p>
            <input
              style={{
                cursor: "pointer",
              }}
              type="checkbox"
              id="topping"
              name="topping"
              value="Paneer"
              checked={this.state.declaration}
              onChange={this.handleCheckBox}
            />{" "}
            I declare that all the information provided are mine and correct to
            the best of my knowledge. Any wrong information might lead to
            disqualification or displinary action taken against me.
          </p>

          <form onSubmit={this.openModelForm}>
            <Button
              variant="primary"
              type="submit"
              className="btn btn-round  btn-sm"
              // onClick={() => this.setState({ yesIsOpen: true })}
              // if (this.state.declaration)
            >
              <FaRegSmile />
              {"     "}Preview
            </Button>
          </form>

          <Modal size="lg" show={this.state.yesIsOpen} onHide={this.closeModal}>
            <Modal.Header closeButton>
              <div className="float-center card-header-title">
                Please Preview your application before submitting(If you find
                incorrect information, go back to previous steps and correct
                them)
              </div>
            </Modal.Header>
            <form onSubmit={this.submitDeclaration}>
              <Modal.Body>
                {this.state.loading === true ? (
                  <>
                    <ReactLoading type="cylon" color="#000" />
                  </>
                ) : null}
                {this.state.network_error === true ? (
                  <>
                    <Alert onClose={this.closeNetworkWarning} severity="error">
                      You are facing netwok problems — Please try again later or
                      contact the system administrator!
                    </Alert>
                  </>
                ) : (
                  <>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                            colSpan="5"
                          >
                            APPLICANT INFORMATION
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "12px",
                            }}
                          >
                            FULL NAME
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "12px",
                            }}
                          >
                            SEX
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            BIRTHDATE
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            CITIZENSHIP
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {this.state.first_name +
                              " " +
                              this.state.middle_name +
                              " " +
                              this.state.last_name}
                          </td>
                          <td>{this.state.sex === "M" ? "Male" : "Female"}</td>
                          <td> {this.state.dob}</td>
                          <td> {this.state.citizenship}</td>
                        </tr>

                        <tr>
                          <th
                            colSpan={5}
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            APPLICATION LEVEL & YEAR
                          </th>
                        </tr>
                        <tr>
                          <th colSpan={3}>Level</th>
                          <th colSpan={2}>Academic Year</th>
                        </tr>
                        <tr>
                          <th colSpan={3}>{this.state.award}</th>
                          <th colSpan={2}>{this.state.accademic_year}</th>
                        </tr>
                      </tbody>
                    </table>
                    <br></br>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                            colSpan="5"
                          >
                            APPLICANT ADDRESS
                          </th>
                        </tr>
                        <tr>
                          <th>Country</th>
                          <th>Region</th>
                          <th>District</th>
                          <th>Phone</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{this.state.countryOfResident}</td>
                          <td>{this.state.region}</td>
                          <td>{this.state.district}</td>
                          <td>{this.state.cellPhone}</td>
                          <td>{this.state.email}</td>
                        </tr>
                      </tbody>
                    </table>
                    {/* {console.log(this.state.college_data)} */}
                    <br></br>

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                            colSpan="5"
                          >
                            PRIMARY SCHOOL INFORMATION
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              width: "20%",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                            }}
                          >
                            School Name
                          </th>
                          <td style={{ fontStyle: "italic" }}>
                            {this.state.school}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              width: "20%",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                            }}
                          >
                            Completion Year
                          </th>
                          <td style={{ fontStyle: "italic" }}>
                            {this.state.year}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br></br>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                            colSpan="7"
                          >
                            NEXT OF KIN INFORMATION
                          </th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "12px",
                            }}
                          >
                            FULL NAME
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "12px",
                            }}
                          >
                            RELATIONSHIP
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            COUNTRY
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            REGION
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            DISTRICT
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            CELL PHONE
                          </th>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                          >
                            ADDRESS
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> {this.state.fullname}</td>
                          <td> {this.state.relationship}</td>
                          <td> {this.state.country}</td>
                          <td> {this.state.region}</td>
                          <td> {this.state.district}</td>
                          <td> {this.state.cellPhone}</td>
                          <td> {this.state.postalBox}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                            colSpan="7"
                          >
                            SECONDARY EDUCATION (O-LEVEL)
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <div style={{ paddingTop: "40px" }}> */}
                        <Accordion defaultActiveKey="0">
                          {this.props.secondary_school.map((option, i) => (
                            <div key={i}>
                              {i === 0 ? (
                                <Accordion.Item eventKey="0" key={i}>
                                  <Accordion.Header
                                    className="accordinHeader"
                                    key={i + "accordinHeader"}
                                  >
                                    {option.center_name
                                      ? option.center_name.toUpperCase()
                                      : option.center_name}{" "}
                                    , {option.index_number} , DIV:
                                    {option.results.division.division}, YEAR:{" "}
                                    {option.year}{" "}
                                  </Accordion.Header>
                                  <Accordion.Body
                                    className="accordionBody"
                                    key={i + "accordionBody"}
                                  >
                                    <table
                                      className="table table-bordered table-striped"
                                      key={i + "table"}
                                    >
                                      <tbody key={i + "tablebody"}>
                                        <tr key={i + "tableHeader"}>
                                          <td key={0 + i}>CODE</td>
                                          <td key={1 + i}>SUBJECT</td>
                                          <td key={2 + i}>PASSMARK</td>
                                        </tr>

                                        {option.results.subjects.map(
                                          (opt, j) => (
                                            <tr key={j + i}>
                                              <td key={j + i + 0}>
                                                {opt.subject_code}
                                              </td>
                                              <td key={j + i + 1}>
                                                {opt.subject_name}
                                              </td>
                                              <td key={j + i + 2}>
                                                {opt.grade}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : (
                                <>
                                  <Accordion.Item
                                    eventKey={i}
                                    key={i + "Accordion"}
                                  >
                                    <Accordion.Header
                                      className="accordinHeader"
                                      key={i + "accordinHeader"}
                                    >
                                      {option.center_name
                                        ? option.center_name.toUpperCase()
                                        : option.center_name}{" "}
                                      , {option.index_number} , DIV:
                                      {option.results.division.division}, YEAR:{" "}
                                      {option.year}
                                    </Accordion.Header>
                                    <Accordion.Body
                                      className="accordionBody"
                                      key={i + "accordionBody"}
                                    >
                                      <table
                                        className="table table-bordered table-striped"
                                        key={i + "table"}
                                      >
                                        <tbody key={i + "tablebody"}>
                                          <tr key={i + "tableHeader"}>
                                            <td key={0 + i}>CODE</td>
                                            <td key={1 + i}>SUBJECT</td>
                                            <td key={2 + i}>PASSMARK</td>
                                          </tr>

                                          {option.results.subjects.map(
                                            (opt, j) => (
                                              <tr key={j + i}>
                                                <td key={j + i + 0}>
                                                  {opt.subject_code}
                                                </td>
                                                <td key={j + i + 1}>
                                                  {opt.subject_name}
                                                </td>
                                                <td key={j + i + 2}>
                                                  {opt.grade}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </>
                              )}
                            </div>
                          ))}
                        </Accordion>
                        {/* </div> */}
                      </tbody>
                    </table>

                    <br></br>

                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{
                              backgroundColor: "#f1f5f7",
                              fontWeight: "bold",
                              fontFamily: "Helvetica",
                              fontSize: "13px",
                            }}
                            colSpan="7"
                          >
                            HIGH SCHOOL EDUCATION (A-LEVEL)
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {/* <div style={{ paddingTop: "40px" }}> */}
                        <Accordion defaultActiveKey="0">
                          {this.props.high_school.map((option, i) => (
                            <div key={i}>
                              {i === 0 ? (
                                <Accordion.Item eventKey="0" key={i}>
                                  <Accordion.Header
                                    className="accordinHeader"
                                    key={i + "accordinHeader"}
                                  >
                                    {option.center_name
                                      ? option.center_name.toUpperCase()
                                      : option.center_name}{" "}
                                    , {option.index_number} , DIV:
                                    {option.results.division.division}, YEAR:{" "}
                                    {option.year}
                                  </Accordion.Header>
                                  <Accordion.Body
                                    className="accordionBody"
                                    key={i + "accordionBody"}
                                  >
                                    <table
                                      className="table table-bordered table-striped"
                                      key={i + "table"}
                                    >
                                      <tbody key={i + "tablebody"}>
                                        <tr key={i + "tableHeader"}>
                                          <td key={0 + i}>CODE</td>
                                          <td key={1 + i}>SUBJECT</td>
                                          <td key={2 + i}>PASSMARK</td>
                                        </tr>

                                        {option.results.subjects.map(
                                          (opt, j) => (
                                            <tr key={j + i}>
                                              <td key={j + i + 0}>
                                                {opt.subject_code}
                                              </td>
                                              <td key={j + i + 1}>
                                                {opt.subject_name}
                                              </td>
                                              <td key={j + i + 2}>
                                                {opt.grade}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : (
                                <>
                                  <Accordion.Item
                                    eventKey={i}
                                    key={i + "Accordion"}
                                  >
                                    <Accordion.Header
                                      className="accordinHeader"
                                      key={i + "accordinHeader"}
                                    >
                                      {option.center_name
                                        ? option.center_name.toUpperCase()
                                        : option.center_name}{" "}
                                      , {option.index_number} , DIV:
                                      {option.results.division.division}, YEAR:{" "}
                                      {option.year}
                                    </Accordion.Header>
                                    <Accordion.Body
                                      className="accordionBody"
                                      key={i + "accordionBody"}
                                    >
                                      <table
                                        className="table table-bordered table-striped"
                                        key={i + "table"}
                                      >
                                        <tbody key={i + "tablebody"}>
                                          <tr key={i + "tableHeader"}>
                                            <td key={0 + i}>CODE</td>
                                            <td key={1 + i}>SUBJECT</td>
                                            <td key={2 + i}>PASSMARK</td>
                                          </tr>

                                          {option.results.subjects.map(
                                            (opt, j) => (
                                              <tr key={j + i}>
                                                <td key={j + i + 0}>
                                                  {opt.subject_code}
                                                </td>
                                                <td key={j + i + 1}>
                                                  {opt.subject_name}
                                                </td>
                                                <td key={j + i + 2}>
                                                  {opt.grade}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </>
                              )}
                            </div>
                          ))}
                        </Accordion>
                        {/* </div> */}
                      </tbody>
                    </table>

                    <table
                      className="table table-bordered"
                      // style={{ width: "90%" }}
                      key={"table" + 1}
                    >
                      <thead>
                        <tr
                          // style={{ borderColor: "#000", color: "#000" }}
                          style={{
                            backgroundColor: "#f1f5f7",
                            fontWeight: "bold",
                            fontFamily: "Helvetica",
                            fontSize: "13px",
                          }}
                          colSpan="5"
                          key={"tr" + 1}
                        >
                          <td
                            colSpan=""
                            style={{
                              borderColor: "#000",
                              color: "#000",
                              width: "5%",
                            }}
                            key={"td" + 1}
                          >
                            #
                          </td>
                          <td
                            colSpan=""
                            style={{
                              borderColor: "#000",
                              color: "#000",
                              width: "60%",
                            }}
                            key={"td" + 2}
                          >
                            PROGRAM
                          </td>

                          <th
                            colSpan=""
                            style={{
                              borderColor: "#000",
                              color: "#000",
                              width: "10%",
                            }}
                          >
                            CAMPUS
                          </th>
                          <th
                            colSpan=""
                            style={{
                              borderColor: "#000",
                              color: "#000",
                              width: "10%",
                            }}
                          >
                            CHOICE
                          </th>
                        </tr>
                      </thead>
                      <tbody key={"tbody" + 3}>
                        {this.props.programs_data.map((opt, i) => (
                          <tr key={"tr" + i}>
                            <td
                              key={i + 0}
                              style={{
                                borderColor: "#000",
                                color: "#000",
                              }}
                            >
                              {count + i}
                            </td>
                            <td
                              key={i + 1}
                              style={{
                                borderColor: "#000",
                                color: "#000",
                              }}
                            >
                              {opt.program}
                            </td>

                            <td
                              key={i + 3}
                              style={{
                                borderColor: "#000",
                                color: "#000",
                              }}
                            >
                              {opt.campus}
                            </td>
                            <td
                              key={i + 2}
                              style={{
                                borderColor: "#000",
                                color: "#000",
                              }}
                            >
                              {opt.choice === 1 ? (
                                <>
                                  1<sup>st</sup>
                                </>
                              ) : null}
                              {opt.choice === 2 ? (
                                <>
                                  2<sup>nd </sup>
                                </>
                              ) : null}
                              {opt.choice === 3 ? (
                                <>
                                  3<sup>rd</sup>
                                </>
                              ) : null}
                              {opt.choice === 4 ? (
                                <>
                                  4<sup>th</sup>
                                </>
                              ) : null}
                              {opt.choice === 5 ? (
                                <>
                                  5<sup>th</sup>
                                </>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn btn-round  btn-sm"
                >
                  <FaUpload />
                  {"     "}Submit Application
                </Button>
                <Button
                  variant="secondary"
                  className="btn btn-round  btn-sm btn btn-round"
                  onClick={this.closeModal}
                >
                  Close
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </fieldset>
      </div>
    );
  }
}

const PerformDeclaration2 = withRouter(PerformDeclaration);
export default PerformDeclaration2;
