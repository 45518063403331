import React, { Component } from "react";
import { FaRegHandPointRight } from "react-icons/fa";

class Verified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    return (
      <>
        <p>
          <b>Instructions</b>
        </p>
        <hr />

        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>1.</span>
            <span style={{ color: "green", fontWeight: "bold" }}>
              Congratulation
            </span>
            , You have been selected to TIA in the programme{" "}
            <span style={{ fontWeight: "bold" }}> "{this.props.program}"</span>{" "}
            in <span style={{ fontWeight: "bold" }}>"{this.props.campus}"</span>{" "}
            Campus,
            {""}
            <span style={{ fontWeight: "bold" }}>
              You have been verified by NACTVET.You can continue with
              registration once the window is open.
            </span>
          </li>
        </ul>
      </>
    );
  }
}
export default Verified;
