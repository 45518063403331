import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
class HighSchoolDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondary_school: [],
    };
  }
  render() {
    return (
      <div style={{ paddingTop: "40px" }}>
        <Accordion defaultActiveKey="0">
          {this.props.high_school.map((option, i) => (
            <div key={i}>
              {i === 0 ? (
                <Accordion.Item eventKey="0" key={i}>
                  <Accordion.Header
                    className="accordinHeader"
                    key={i + "accordinHeader"}
                  >
                    {option.center_name
                      ? option.center_name.toUpperCase()
                      : option.center_name}{" "}
                    , {option.index_number} , DIV:
                    {option.results.division.division}, YEAR: {option.year}{" "}
                  </Accordion.Header>
                  <Accordion.Body
                    className="accordionBody"
                    key={i + "accordionBody"}
                  >
                    <table
                      className="table table-bordered table-striped"
                      key={i + "table"}
                    >
                      <tbody key={i + "tablebody"}>
                        <tr key={i + "tableHeader"}>
                          <td key={0 + i}>CODE</td>
                          <td key={1 + i}>SUBJECT</td>
                          <td key={2 + i}>PASSMARK</td>
                        </tr>

                        {option.results.subjects.map((opt, j) => (
                          <tr key={j + i}>
                            <td key={j + i + 0}>{opt.subject_code}</td>
                            <td key={j + i + 1}>{opt.subject_name}</td>
                            <td key={j + i + 2}>{opt.grade}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <>
                  <Accordion.Item eventKey={i} key={i + "Accordion"}>
                    <Accordion.Header
                      className="accordinHeader"
                      key={i + "accordinHeader"}
                    >
                      {option.center_name
                        ? option.center_name.toUpperCase()
                        : option.center_name}{" "}
                      , {option.index_number} , DIV:
                      {option.results.division.division}, YEAR: {option.year}{" "}
                    </Accordion.Header>
                    <Accordion.Body
                      className="accordionBody"
                      key={i + "accordionBody"}
                    >
                      <table
                        className="table table-bordered table-striped"
                        key={i + "table"}
                      >
                        <tbody key={i + "tablebody"}>
                          <tr key={i + "tableHeader"}>
                            <td key={0 + i}>CODE</td>
                            <td key={1 + i}>SUBJECT</td>
                            <td key={2 + i}>PASSMARK</td>
                          </tr>

                          {option.results.subjects.map((opt, j) => (
                            <tr key={j + i}>
                              <td key={j + i + 0}>{opt.subject_code}</td>
                              <td key={j + i + 1}>{opt.subject_name}</td>
                              <td key={j + i + 2}>{opt.grade}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              )}
            </div>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default HighSchoolDisplay;
