import React, { Component } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { withRouter } from "react-router";
import changeStage from "../../util/changeStage";
import ReactLoading from "react-loading";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Alert from "@mui/material/Alert";
import params from "../../util/api_routes/params";
import { FaHandPointRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import api from "../../util/api";

class ApplyingProgramDisplayComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programs_data: [],
      stage: parseInt(localStorage.getItem("stageid")),
      loading: null,
      network_error: false,
    };
  }

  changePrograme = async (e) => {
    e.preventDefault();
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };

    await api
      .post(params.CHANGE_PROGRAMME, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              new_stage: localStorage.setItem("stageid", 7),
              submit_application: res.data.data,
              loading: false,
              network_error: false,
            });

            window.location.reload();
            // this.props.history.push({
            //   state: {
            //     submit_application_data: this.state.submit_application,
            //   },
            //   pathname: "/applying-programs/applying-programs",
            // });
          } else {
            this.setState({
              network_error: true,
              loading: false,
            });
            console.log(parseInt(localStorage.getItem("stageid")));
          }
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, loading: false });
        console.log(parseInt(localStorage.getItem("stageid")));
      });
  };

  nextToConfirmationSubmission = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 8,
    };
    console.log(parseInt(localStorage.getItem("stageid")));
    let result = await changeStage(payLoad);
    if (result === 1) {
      this.setState({ loading: false, network_error: false });
      this.props.history.push("/application/declaration");
    } else {
    }
  };
  render() {
    let count = 1;
    return (
      <>
        {this.props.programs_data.length > 0 ? (
          <>
            {this.state.loading === true ? (
              <>
                <div className="row">
                  <div className="col-sm-6"></div>
                  <div className="col-sm-2">
                    <ReactLoading type="cylon" color="#000" />
                  </div>
                  <div className="col-sm-6"></div>
                </div>
              </>
            ) : (
              <>
                {this.state.loading === true ? (
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-10">
                      <div className="alert alert-warning">
                        <strong>Warning!</strong> You are facing a connection
                        problem, please contact the system administrator OR try
                        later
                      </div>
                    </div>
                    <div className="col-sm-1"></div>
                  </div>
                ) : null}
              </>
            )}
            <table
              className="table table-bordered"
              style={{ width: "90%" }}
              key={"table" + 1}
            >
              <thead>
                <tr
                  style={{ borderColor: "#000", color: "#000" }}
                  key={"tr" + 1}
                >
                  <td
                    colSpan=""
                    style={{
                      borderColor: "#000",
                      color: "#000",
                      width: "5%",
                    }}
                    key={"td" + 1}
                  >
                    #
                  </td>
                  <td
                    colSpan=""
                    style={{
                      borderColor: "#000",
                      color: "#000",
                      width: "60%",
                    }}
                    key={"td" + 2}
                  >
                    PROGRAM
                  </td>

                  <th
                    colSpan=""
                    style={{
                      borderColor: "#000",
                      color: "#000",
                      width: "10%",
                    }}
                  >
                    CAMPUS
                  </th>
                  <th
                    colSpan=""
                    style={{
                      borderColor: "#000",
                      color: "#000",
                      width: "10%",
                    }}
                  >
                    CHOICE
                  </th>
                </tr>
              </thead>
              <tbody key={"tbody" + 3}>
                {this.props.programs_data.map((opt, i) => (
                  <tr key={"tr" + i}>
                    <td
                      key={i + 0}
                      style={{
                        borderColor: "#000",
                        color: "#000",
                      }}
                    >
                      {count + i}
                    </td>
                    <td
                      key={i + 1}
                      style={{
                        borderColor: "#000",
                        color: "#000",
                      }}
                    >
                      {opt.program}
                    </td>

                    <td
                      key={i + 3}
                      style={{
                        borderColor: "#000",
                        color: "#000",
                      }}
                    >
                      {opt.campus}
                    </td>
                    <td
                      key={i + 2}
                      style={{
                        borderColor: "#000",
                        color: "#000",
                      }}
                    >
                      {opt.choice === 1 ? (
                        <>
                          1<sup>st</sup>
                        </>
                      ) : null}
                      {opt.choice === 2 ? (
                        <>
                          2<sup>nd </sup>
                        </>
                      ) : null}
                      {opt.choice === 3 ? (
                        <>
                          3<sup>rd</sup>
                        </>
                      ) : null}
                      {opt.choice === 4 ? (
                        <>
                          4<sup>th</sup>
                        </>
                      ) : null}
                      {opt.choice === 5 ? (
                        <>
                          5<sup>th</sup>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Edit Programmes after submitting programmes and before declaration */}
            {this.state.stage === 7 || this.state.stage === 8 ? (
              <>
                <div
                  style={{
                    color: "blue",
                    fontSize: "17px",
                  }}
                >
                  <ul>
                    <li>
                      {" "}
                      <FaHandPointRight />
                      Click{" "}
                      <Link>
                        <span
                          style={{
                            color: "green",
                            fontSize: "18px",
                          }}
                          onClick={this.changePrograme}
                        >
                          Here
                        </span>{" "}
                      </Link>
                      to edit programmes.
                    </li>
                  </ul>
                  {/* </Link> */}
                </div>
              </>
            ) : null}

            {/* Edit Programmes after submitting programmes and before declaration */}

            {this.state.stage === 7 ? (
              <>
                <Row>
                  <Col xs="auto" style={{ paddingTop: "25px" }}>
                    <Button
                      type="submit"
                      className="btn btn-round  btn-md"
                      variant="primary"
                      onClick={this.nextToConfirmationSubmission.bind(this)}
                    >
                      Continue to Declaration and Submission{" "}
                      <FaArrowAltCircleRight size={20} />
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {this.state.stage > 7 ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <Alert severity="warning">
                            Please fill the previous stages
                          </Alert>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  </>
                )}
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}
const ApplyingProgramDisplay = withRouter(ApplyingProgramDisplayComp);
export default ApplyingProgramDisplay;
