import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ResetAccount";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Education Profile
import PrimaryEducation from "../pages/Education/primary_education/primary_education";
import EditPrimaryEducation from "../pages/Education/primary_education/edit_primary_education";
import SecondarySchool from "../pages/Education/secondary-school/index";
// High School informatio

import HighSchool from "../pages/Education/high-school/index";

// college information
import College from "../pages/Education/college/index";
//Setup

// Applying Programs
import ApplyingPrograms from "../pages/applying-programs/index";

//Application declaration
import Declaration from "../pages/Declaration/index";

//Next of Kin
import NextOfKin from "../pages/NextOfKin/index";

//application submission
import Submission from "../pages/submission/index";

//application confirmation
import Confirm from "../pages/Confirm/index";

//application unconfirmation
import Unconfirm from "../pages/Unconfirm/index";

//application cancellation
import Cancel from "../pages/Cancel/index";

//final selection
import Selection from "../pages/final-selection-results/index";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  //Education Links
  { path: "/education/primary", component: PrimaryEducation },
  { path: "/education/edit-primary/:id", component: EditPrimaryEducation },
  { path: "/education/secondary-school", component: SecondarySchool },
  { path: "/education/high-school", component: HighSchool },
  { path: "/education/college", component: College },
  {
    path: "/applying-programs/applying-programs",
    component: ApplyingPrograms,
  },
  { path: "/personal/nextofkin", component: NextOfKin },
  { path: "/application/declaration", component: Declaration },
  { path: "/after/submission", component: Submission },
  { path: "/after/confirm", component: Confirm },
  { path: "/after/unconfirm", component: Unconfirm },
  { path: "/after/cancel", component: Cancel },
  { path: "/after/finalselection", component: Selection },

  //Setup

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/reset-account", component: ForgetPwd },
  { path: "/sign-up", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes };
