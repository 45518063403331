import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { FaRegHandPointDown } from "react-icons/fa";
import ReactLoading from "react-loading";
import SecondaryFillingInstruction from "../secondary-school/secondaryFillingInstruction";
import SecondarySchoolDisplay from "../secondary-school/secondarySchoolDisplay";
import AddSecondaryEducation from "../secondary-school/addSecondaryEducation";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import Alert from "@mui/material/Alert";
class SecondarySchool extends Component {
  state = {
    internal_track: null,
    external_track: null,
    answer_id: null,
    school_data: [],
    stage: parseInt(localStorage.getItem("stageid")),
    info_loading: null,
    network_error: false,
  };

  componentDidMount() {
    this.getSecondarySchools();
  }

  handleSelectionInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getSecondarySchools = async () => {
    this.setState({ info_loading: true });
    await api
      .get(params.OLEVEL_LIST + parseInt(localStorage.getItem("id")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              school_data: res.data.data,
              info_loading: false,
              network_error: false,
            });
          }
        } else {
          this.setState({
            school_data: [],
            info_loading: false,
            network_error: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ info_loading: false, network_error: true });
      });
  };

  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Secondary Education
                </div>
                <div className="float-right card-header-title">
                  Application Number : {localStorage.getItem("id")}
                </div>
              </div>

              <div className="card-body">
                <div className="block">
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        <ReactLoading type="cylon" color="#000" />
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : (
                    <>
                      {this.state.network_error === true ? (
                        <>
                          <div className="row">
                            <div className="col-sm-1"></div>
                            <div className="col-sm-10">
                              <div className="alert alert-warning">
                                <strong>Warning!</strong> You are facing a
                                connection problem, please contact the system
                                administrator OR try later
                              </div>
                            </div>
                            <div className="col-sm-1"></div>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.school_data.length > 0 ? (
                            <>
                              {this.state.stage === 4 ? (
                                <div className="row">
                                  <div className="col-sm-1"></div>
                                  <div className="col-sm-5">
                                    <SecondaryFillingInstruction />
                                    <AddSecondaryEducation />
                                  </div>
                                  <div className="col-sm-6">
                                    <SecondarySchoolDisplay
                                      secondary_school={this.state.school_data}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {this.state.stage > 4 ? (
                                    <>
                                      {" "}
                                      <div className="row">
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-10">
                                          <SecondarySchoolDisplay
                                            secondary_school={
                                              this.state.school_data
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-1"></div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <>
                                        <div className="row">
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-10">
                                            <Alert severity="warning">
                                              Please fill the previous stages
                                              Information
                                            </Alert>
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>
                                      </>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <div className="row">
                              <div className="col-sm-4">
                                <p>
                                  <b>Instructions</b>
                                </p>
                                <hr />

                                <ul>
                                  Please fill your Secondary education through
                                  answering different questions which will be
                                  prompted to you.{" "}
                                  <FaRegHandPointDown size={25} />
                                  <br></br>
                                  <li>
                                    If you have not attended any Secondary
                                  </li>
                                  education select no.
                                  <li style={{ paddingTop: "5px" }}>
                                    <b>Note</b>. The fields with * are mandatory
                                  </li>
                                </ul>
                              </div>
                              <div
                                className="col-sm-7"
                                style={{ paddingTop: "55px" }}
                              >
                                <div className="form-group">
                                  <label>
                                    Do you have another ordinary level
                                    certificate (Reset) ? *
                                  </label>
                                  <select
                                    name="olevel_question"
                                    className="selection-box  input-placeholder"
                                    value={this.state.name}
                                    onChange={this.handleSelectionInput}
                                  >
                                    <option key="">
                                      {" "}
                                      -- Select the answer --{" "}
                                    </option>
                                    <option key="0">Yes</option>
                                    <option key="1">No</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
export default SecondarySchool;
