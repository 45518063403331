import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/logout-icon.jpg";
// import toTitleconverttoTitle from "../../util/toTitleConveter";
import toTitleconverttoTitle from "../../../util/toTitleConveter";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      user_data: JSON.parse(localStorage.getItem("authUser")),
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    let username =
      toTitleconverttoTitle(this.state.user_data.firstname) +
      " " +
      toTitleconverttoTitle(this.state.user_data.surname);

    //    if(localStorage.getItem("authUser"))
    //    {
    //         const obj = JSON.parse(localStorage.getItem("authUser"));

    //         const uNm = obj.user_email.split("@")[0];
    //         username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    //    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
          style={{ backgroundColor: "#EBFDED" }}
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            style={{ backgroundColor: "#EBFDED" }}
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={avatar2}
              alt="Header Avatar"
            />

            <span
              className="d-none d-xl-inline-block ml-1 text-transform"
              style={{ color: "black", fontWeight: "bold" }}
            >
              {username}
            </span>
            <i
              style={{ color: "black" }}
              className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"
            ></i>
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem href="#">
              <i className="ri-user-line align-middle mr-1"></i>{" "}
              {this.props.t("Profile")}
            </DropdownItem> */}

            {/* <DropdownItem divider /> */}
            <DropdownItem className="text-danger" href="/logout">
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>{" "}
              {this.props.t("Logout")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
