import React, { Component } from "react";
import { FaRegHandPointRight } from "react-icons/fa";
class DeclarationInstruction extends Component {
  render() {
    return (
      <>
        <p>
          <b>Instructions</b>
        </p>
        <hr />

        <ul>
          Now you are about to finish your online application to TIA;
          <li>
            1. By checking the box you declare that all the information provided
            are correct.{" "}
          </li>{" "}
          <li>
            {" "}
            2. Please check the box to declare and then click the button to
            finish the application. <FaRegHandPointRight size={25} />
          </li>
        </ul>
      </>
    );
  }
}
export default DeclarationInstruction;
